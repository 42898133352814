import dayjs from 'dayjs'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Dropdown from 'react-multilevel-dropdown'

import { useVeeziWebSessionsAll } from '../hooks/veezi-web-sessions'
import caret_down from '../images/caret_down.svg'
import { flatten, groupBy } from '../util/array'
import Link from './link'
import Modal from './modal'
import styles from './quick-booking-button.module.css'

const DATE_FORMAT = 'DD MMM YYYY'
const TIME_FORMAT = 'HH:mm'
const SORT_BY_EVENTS = 'event'
const SORT_BY_DATES = 'date'

const useQuickBooking = () => {
  const {
    locations: { edges: locEdges },
    events: { edges: evEdges },
  } = useStaticQuery(
    graphql`
      query QuickBookingQuery {
        locations: allMarkdownRemark(
          filter: { fields: { collection: { eq: "locations" } } }
          sort: { fields: [frontmatter___title], order: [ASC] }
        ) {
          edges {
            node {
              frontmatter {
                title
                veeziToken
              }
              fields {
                venues {
                  frontmatter {
                    title
                    screens {
                      veeziScreenId
                    }
                    accessibility
                  }
                }
              }
            }
          }
        }
        events: allMarkdownRemark(
          filter: { fields: { collection: { eq: "films-and-events" } } }
          sort: { fields: [frontmatter___title], order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                id
                veeziFilmId
                title
                bookingURL
                startDate
              }
              fields {
                venue {
                  frontmatter {
                    title
                    screens {
                      veeziScreenId
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const locationsRef = useRef(
    (locEdges || []).map(e => ({
      ...e.node.frontmatter,
      screens: Array.from(
        new Set(
          flatten(
            (e.node.fields.venues || []).map(v =>
              (v.frontmatter.screens || []).map(s => s.veeziScreenId)
            )
          )
        )
      ),
      venues: (e.node.fields.venues || []).map(e => ({
        ...e.frontmatter,
      })),
    }))
  )

  const eventsRef = useRef(
    evEdges.map(e => ({
      ...e.node.frontmatter,
      venue: e.node.fields.venue ? e.node.fields.venue.frontmatter : null,
    }))
  )

  return {
    locations: locationsRef.current,
    events: eventsRef.current,
  }
}

const getOrderedDateKeys = dateMapping =>
  Object.keys(dateMapping).sort((a, b) => new Date(a) - new Date(b))

const groupDateSessions = sessions => {
  const temp = sessions.reduce((map, s) => {
    const d = dayjs(s.PreShowStartTime || s.startDate)
      .startOf('day')
      .toISOString()

    map[d] = [...(map[d] || []), s]
    return map
  }, {})

  Object.keys(temp).forEach(k =>
    temp[k].sort(
      (a, b) => new Date(a.PreShowStartTime) - new Date(b.PreShowStartTime)
    )
  )

  return temp
}

const groupSessionByLocations = (events, veeziWebSessions, locations) => {
  if (!veeziWebSessions) return []
  const groupByLocations = groupBy(
    veeziWebSessions.filter(s => !s.TicketsSoldOut),
    s => {
      const location = locations.find(l => l.screens.includes(s.ScreenId))
      if (location) return location.title
      // All location will temporarily default to Golden Mile Tower for now
      // return 'Golden Mile Tower'
    }
  )
  groupByLocations['Everywhere'] = veeziWebSessions.filter(s => !s.TicketsSoldOut)

  return groupByLocations
}

const groupSessionsByEventsAndLocations = (
  events,
  veeziWebSessions,
  locations
) => {
  const groupByLocations = groupSessionByLocations(
    events,
    veeziWebSessions,
    locations
  )

  Object.keys(groupByLocations).forEach(l => {
    groupByLocations[l] = groupBy(groupByLocations[l], s => {
      if (s.id) return s.id

      const event = events.find(e => e.veeziFilmId === s.FilmId)

      if (event) return event.veeziFilmId

      return false
    })
  })

  Object.keys(groupByLocations).forEach(l => {
    Object.keys(groupByLocations[l]).forEach(e => {
      groupByLocations[l][e] = groupDateSessions(groupByLocations[l][e])
    })
  })

  return groupByLocations
}

const groupSessionsByDatesAndLocations = (
  events,
  veeziWebSessions,
  locations
) => {
  const groupByLocations = groupSessionByLocations(
    events,
    veeziWebSessions,
    locations
  )

  Object.keys(groupByLocations).forEach(l => {
    groupByLocations[l] = groupDateSessions(groupByLocations[l])
  })

  Object.keys(groupByLocations).forEach(l => {
    Object.keys(groupByLocations[l]).forEach(e => {
      groupByLocations[l][e] = groupBy(groupByLocations[l][e], s => {
        if (s.id) return s.id

        const event = events.find(e => e.veeziFilmId === s.FilmId)

        if (event) return event.veeziFilmId

        return false
      })
    })
  })

  return groupByLocations
}

const QuickBookingModal = ({ className, modalState, onClose, onVaxxedClick }) => {
  const { locations, events } = useQuickBooking()
  const currentLocationRef = useRef('Everywhere')
  const veeziWebSessions = useVeeziWebSessionsAll({})
  const groupByEvents = useRef(
    groupSessionsByEventsAndLocations(events, veeziWebSessions, locations)
  )
  const groupByDates = useRef(
    groupSessionsByDatesAndLocations(events, veeziWebSessions, locations)
  )

  const sortBy = useRef()
  const currentEventRef = useRef()
  const currentDateRef = useRef()
  const currentSessionRef = useRef()

  const locationSelectRef = useRef()
  const eventSelectRef = useRef()
  // const dateSelectRef = useRef()
  // const sessionSelectRef = useRef()

  // const locationOptionsEl = useRef()
  const eventOptionsEl = useRef()
  const dateOptionsEl = useRef()
  const dateMobileOptionsEl = useRef()

  const [eventOptions, setEventOptions] = useState([])
  const [dateOptions, setDateOptions] = useState([])
  const [sessionOptions, setSessionOptions] = useState([])

  const [currentLink, setCurrentLink] = useState()

  const screenAccessibility = useRef(
    locations.reduce((map, l) => {
      l.venues.forEach(v =>
        (v.screens || []).forEach(s => (map[s.veeziScreenId] = v.accessibility))
      )
      return map
    }, {})
  )

  const onLocationChange = useCallback(
    location => {
      const valueOfLocationRef = locationSelectRef.current.value
      currentLocationRef.current = valueOfLocationRef

      // for sort by events
      eventOptionsEl.current = events
        .filter(e =>
          Object.keys(groupByEvents.current[valueOfLocationRef] || {}).includes(
            e.veeziFilmId || e.id
          )
        )
        .map((e, i) => (
          <option value={e.veeziFilmId || e.id} key={i}>
            {e.title}
          </option>
        ))
      setEventOptions(groupByEvents.current[valueOfLocationRef] || [])

      setDateOptions(groupByDates.current[valueOfLocationRef] || [])

      // for sort by dates
      dateOptionsEl.current = getOrderedDateKeys(
        groupByDates.current[valueOfLocationRef] || {}
      )
        .filter(d => dayjs(d).isBefore(dayjs().add(1, 'week')))
        .map((d, i) => {
          //<option value={d} key={i}>
          // {dayjs(d).format(DATE_FORMAT)}
          // </option>
          let timeOptions = []
          if (dateOptions[d]) {
            const dt = dateOptions[d]
            Object.keys(dt).forEach((o) => {
              timeOptions = timeOptions.concat(dt[o])
            })
          }

          return (
            <Dropdown.Item className="custom-dropdown-item" key={i}>
              {dayjs(d).format(DATE_FORMAT + ', ddd')}
              {timeOptions && timeOptions.length > 0 ?
                (<Dropdown.Submenu position="right">
                  {timeOptions.map((s, j) => (
                    <Dropdown.Item className="custom-dropdown-sub-item" onClick={() => {
                      onDateChange(d)
                      onSessionChange(s)
                    }} key={j}>
                      <span className={`${s.FewTicketsLeft ? styles.itemSellingFast : ''}`}>
                        {s.FewTicketsLeft && <span className={styles.tag}>Selling Fast</span>}
                        <span>{dayjs(s.PreShowStartTime || s.startDate).format(TIME_FORMAT)}</span>
                      </span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Submenu>) : null
              }
            </Dropdown.Item>
          )
        })

      dateMobileOptionsEl.current = getOrderedDateKeys(
        groupByDates.current[location] || {}
      )
        .filter(d => dayjs(d).isBefore(dayjs().add(1, 'week')))

      // reset
      currentEventRef.current = null
      currentDateRef.current = null
      currentSessionRef.current = null
      setSessionOptions([])
      setCurrentLink()

      // reset ref
      sortBy.current = null
      eventSelectRef.current.value = ''

      // dateSelectRef.current.value = ''
      // sessionSelectRef.current.value = ''
    },
    [events]
  )

  const onEventsChange = useCallback(
    filmId => {
      currentEventRef.current = filmId
      // sessionSelectRef.current.value = ''
      setCurrentLink()

      if (!currentDateRef.current) {
        sortBy.current = SORT_BY_EVENTS
      }

      if (sortBy.current === SORT_BY_EVENTS) {
        setDateOptions((eventOptions || {})[filmId] || {})

        dateOptionsEl.current = getOrderedDateKeys(
          (eventOptions || {})[filmId] || {}
        ).map((d, i) => {
          // <option value={d} key={i}>
          // {dayjs(d).format(DATE_FORMAT)}
          // </option>
          let timeOptions = []
          if (((eventOptions || {})[filmId] || {})[d]) {
            const dt = ((eventOptions || {})[filmId] || {})[d]
            Object.keys(dt).forEach((o) => {
              timeOptions = timeOptions.concat(dt[o])
            })
          }

          return (
            <Dropdown.Item className="custom-dropdown-item" key={i}>
              {dayjs(d).format(DATE_FORMAT + ', ddd')}
              {timeOptions && timeOptions.length > 0 ?
                (<Dropdown.Submenu position="right">
                  {timeOptions.map((s, j) => (
                    <Dropdown.Item className="custom-dropdown-sub-item" onClick={() => {
                      onDateChange(d)
                      onSessionChange(s)
                    }} key={j}>
                      <span className={`${s.FewTicketsLeft ? styles.itemSellingFast : ''}`}>
                        {s.FewTicketsLeft && <span className={styles.tag}>Selling Fast</span>}
                        <span>{dayjs(s.PreShowStartTime || s.startDate).format(TIME_FORMAT)}</span>
                      </span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Submenu>) : null
              }
            </Dropdown.Item>
          )
        })

        dateMobileOptionsEl.current = getOrderedDateKeys(
          (eventOptions || {})[filmId] || {}
        )

        currentDateRef.current = null
        currentSessionRef.current = null
        setSessionOptions([])

        // reset select
        // dateSelectRef.current.value = ''
      } else {
        const dt = (eventOptions || {})[filmId] || [];
        let timeOptions = [];
        Object.keys(dt).forEach((o) => {
          timeOptions = timeOptions.concat(dt[o]);
        });
        setSessionOptions(timeOptions);
      }
    },
    [currentDateRef, eventOptions]
  )

  const onDateChange = useCallback(
    date => {
      currentDateRef.current = date
      // sessionSelectRef.current.value = ''
      setCurrentLink()

      if (!currentEventRef.current) {
        sortBy.current = SORT_BY_DATES
      }

      if (sortBy.current === SORT_BY_DATES) {
        eventOptionsEl.current = events
          .filter(e =>
            Object.keys(
              groupByDates.current[currentLocationRef.current][date] || {}
            ).includes(e.veeziFilmId || e.id)
          )
          .map((e, i) => (
            <option value={e.veeziFilmId || e.id} key={i}>
              {e.title}
            </option>
          ))
        setEventOptions(
          groupByDates.current[currentLocationRef.current][date] || []
        )
        setSessionOptions([])

        // reset select
        eventSelectRef.current.value = ''
      } else {
        const dt = (dateOptions || {})[date] || [];
        let timeOptions = [];
        Object.keys(dt).forEach((o) => {
          timeOptions = timeOptions.concat(dt[o]);
        });
        setSessionOptions(timeOptions);
      }
    },
    [dateOptions, events]
  )

  const onSessionChange = useCallback(
    session => {
      // const selectedSession = sessionOptions[index] || {}
      const findLocation = locations.find(o => o.screens.includes(session.ScreenId));
      if (findLocation) {
        currentLocationRef.current = findLocation.title;
        locationSelectRef.current.value = findLocation.title;
      }
      const selectedSession = session
      const url = selectedSession.Url || selectedSession.bookingURL
      setCurrentLink(url)
      currentSessionRef.current = selectedSession
      onClose();
    },
    [sessionOptions]
  )

  const handleNewSessions = useCallback(() => {
    if (locations.length === 1) {
      onLocationChange(locations[0].title)
    } else {
      onLocationChange('Everywhere')
    }

  }, [locations, onLocationChange])

  useEffect(() => {
    groupByEvents.current = groupSessionsByEventsAndLocations(
      events,
      veeziWebSessions,
      locations
    )
    groupByDates.current = groupSessionsByDatesAndLocations(
      events,
      veeziWebSessions,
      locations
    )

    handleNewSessions()
  }, [events, locations, handleNewSessions, veeziWebSessions])

  // useEffect(() => {
  //   handleNewSessions()
  // }, [handleNewSessions, modalState])
  return (
    <>
      <div className={styles.selectWrapper}>
        {(locations && locations.length >= 1) ? (
          <select
            className={styles.select}
            name="Locations"
            onChange={ev => onLocationChange(ev.target.value)}
            ref={locationSelectRef}
            tabIndex="0"
          >
            <option value='Everywhere' key={0}>
              Everywhere
            </option>
            {locations.map((location, index) => (<option value={location.title} key={index + 1}>
              {location.title}
            </option>))}
            {/* {locationOptionsEl.current} */}
          </select>
        ) : (
          ''
        )}
        <select
          className={styles.select}
          name="Films/Events"
          onChange={ev => onEventsChange(ev.target.value)}
          ref={eventSelectRef}
          tabIndex={0}
        >
          <option value="" hidden={true} disabled={true}>
            Films/Events
          </option>
          {eventOptionsEl.current}
        </select>
        {/*<select
          className={styles.select}
          name="Date"
          onChange={ev => onDateChange(ev.target.value)}
          ref={dateSelectRef}
          tabIndex={0}
        >
          <option value="" hidden={true} disabled={true}>
            Date/Time
          </option>
          {dateOptionsEl.current}
        </select>*/}
        <div className={`${styles.datetimeDropdown} custom-dropdown`}>
          <Dropdown
            title={
              (currentDateRef.current && currentSessionRef.current) ?
                dayjs(currentDateRef.current).format(DATE_FORMAT + ', ddd') + " " +
                dayjs(currentSessionRef.current.PreShowStartTime || currentSessionRef.current.startDate).format(TIME_FORMAT) :
                'DATE/TIME'}
            wrapperClassName="custom-dropdown-wrapper"
            buttonClassName="custom-dropdown-btn"
            menuClassName="custom-dropdown-menu datetime-select">
            <Dropdown.Item className="custom-dropdown-item">
              Please Choose Date/Time
            </Dropdown.Item>
            {dateOptionsEl.current}
          </Dropdown>
        </div>

        <div className={styles.groupSelect}>
          <h3>Select a date</h3>
          <div>
            {dateMobileOptionsEl.current &&
              dateMobileOptionsEl.current.map((d, i) => {
                return (
                  <div className={`${currentDateRef.current == d ? styles.groupSelectItemActive : ''} ${styles.groupSelectItem}`} key={i} onClick={() => onDateChange(d)}>
                    <span>{dayjs(d).format('DD MMM, ddd')}</span>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div className={styles.groupSelect}>
          <h3>Select a time</h3>
          {/*{timeMobileOptionsEl.current}*/}
          <div>
            {sessionOptions.map((s, i) => (
              <div className={`${currentSessionRef.current && currentSessionRef.current.Id == s.Id ? styles.groupSelectItemActive : ''} ${s.TicketsSoldOut ? styles.groupSelectItemDisabled : ''} ${styles.groupSelectItem} ${s.FewTicketsLeft ? styles.itemSellingFast : ''}`} key={i} onClick={() => onSessionChange(s)}>
                {s.TicketsSoldOut && (<span>SOLD OUT </span>)}
                {s.FewTicketsLeft && <span className={styles.tag}>Selling Fast</span>}
                <span>{dayjs(s.PreShowStartTime || s.startDate).format(TIME_FORMAT)}</span>
              </div>
            ))}
          </div>
        </div>
        {/*<select
          className={classes(
            styles.select,
            !sessionOptions || (!sessionOptions.length && styles.selectDisabled)
          )}
          name="Time"
          onChange={ev => onSessionChange(ev.target.value)}
          ref={sessionSelectRef}
          tabIndex={0}
        >
          <option value="" hidden={true} disabled={true}>
            Time
          </option>
          {sessionOptions.map((s, i) => (
            <option value={i} key={i}>
              {dayjs(s.PreShowStartTime || s.startDate).format(TIME_FORMAT)}
            </option>
          ))}
        </select>*/}
        {!currentLink && (<div className="w-full text-center">
          <button
            className={`${styles.bookBtn} block w-full btn-primary`}
            disabled={true}
          >
            <span className={styles.bookBtnText}>Book Now</span>
            <span className={styles.bookBtnTextMobile}>Buy Tickets</span>
          </button>
        </div>)}
        {currentLink && (
          <a
            className={`${styles.bookBtn
              } block w-full btn-primary relative ${currentSessionRef.current
                .TicketsSoldOut && styles.itemSoldOut}`}
            style={(screenAccessibility.current[currentSessionRef.current.ScreenId] || []).includes('vaxxed') ? { paddingTop: "10px", paddingBottom: "10px" } : {}}
            onClick={() => {
              if ((screenAccessibility.current[currentSessionRef.current.ScreenId] || []).includes('vaxxed')) {
                onVaxxedClick(currentLink)
              } else {
                typeof window !== 'undefined' && window.open(currentLink, '_blanl')
              }
            }}
          >
            {currentSessionRef.current.TicketsSoldOut ? (
              <span className={styles.tag}>Sold Out</span>
            ) : null}
            {(
              screenAccessibility.current[currentSessionRef.current.ScreenId] ||
              []
            ).includes('vaxxed') ? (
              <span className={styles.vaxxed}>Vaxxed Hall</span>
            ) : null}
            <span className="relative">
              {(
                screenAccessibility.current[currentSessionRef.current.ScreenId] ||
                []
              ).includes('wheelchair') ? (
                <svg
                  className={styles.accessibility}
                  width="13"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="#FFF">
                    <path d="M8.647 13.008c-.15.238-.338.439-.544.64C7.26 14.488 6.06 15 4.746 15a4.783 4.783 0 01-3.358-1.352A4.54 4.54 0 010 10.376c0-1.279.525-2.43 1.388-3.27.169-.165.338-.311.525-.44l.413.97c-.075.054-.131.127-.206.182a3.544 3.544 0 00-1.088 2.558c0 1.006.412 1.901 1.088 2.559a3.767 3.767 0 002.626 1.06c1.031 0 1.95-.402 2.626-1.06.28-.256.506-.567.675-.896l.6.969zM2.059 0c.669 0 1.235.556 1.235 1.25 0 .677-.566 1.25-1.235 1.25A1.249 1.249 0 01.824 1.25C.824.556 1.373 0 2.059 0z" />
                    <path d="M1.706 4.506a.861.861 0 011.605-.631l.434 1.04h2.908c.378 0 .699.296.699.686a.704.704 0 01-.699.688h-2.36l.34.835H7.37c.32 0 .623.186.774.465l2.284 3.807.7-.316c.433-.204.943 0 1.15.428a.866.866 0 01-.433 1.132l-1.417.613a.86.86 0 01-1.095-.334L6.861 8.833H4.047a.853.853 0 01-.793-.539L1.706 4.506z" />
                  </g>
                </svg>
              ) : null}
              {(
                screenAccessibility.current[currentSessionRef.current.ScreenId] ||
                []
              ).includes('closedcaptions') ? (
                <svg
                  className={styles.accessibility}
                  width="22"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none">
                    <path stroke="#FFF" d="M.5.5h20.429v14H.5z" />
                    <path
                      d="M5.357 8.036c0-1.476 1.118-2.679 2.562-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.843 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.825.786c-1.451 0-2.569-1.195-2.569-2.678zm5.25 0c0-1.476 1.117-2.679 2.561-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.844 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.824.786c-1.452 0-2.57-1.195-2.57-2.678z"
                      fill="#FFF"
                    />
                  </g>
                </svg>
              ) : null}
              <span className={styles.bookBtnText}>Book Now</span>
              { 
                currentLocationRef.current &&
                currentDateRef.current &&
                currentSessionRef.current ?
                <span className={styles.bookBtnTextMobile}>Buy Tickets For {`${currentLocationRef.current} On ${dayjs(currentDateRef.current).format('DD MMM, ddd')} At ${dayjs(currentSessionRef.current.PreShowStartTime || currentSessionRef.current.startDate).format(TIME_FORMAT)}`}</span>
                : 'Buy Tickets'
              }
            </span>
          </a>
        )}
        <button
          id="reset-filters-btn"
          className={`${styles.resetFilter} ${(currentLocationRef.current && currentDateRef.current && currentSessionRef.current) ? styles.resetFilterHigher : ''}`}
          onClick={() => {
            currentLocationRef.current = null
            locationSelectRef.current.value = 'Everywhere'
            handleNewSessions()
          }}
        >
          Reset Filters
          </button>
      </div>
    </>
  )
}

const QuickBookingButton = ({ className, onModalOpen, modalClass }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isVaxxedOpen, setIsVaxxedOpen] = useState(false)
  const [bookingLink, setBookingLink] = useState(null)
  const isFocused = useRef(false)
  const isClickLocked = useRef(true)
  const modalRef = useRef()

  const onModalOpenRef = useRef(isOpened => {
    if (onModalOpen) onModalOpen(isOpened)
  })

  const lockdownClick = useRef(() => {
    isClickLocked.current = true
    setTimeout(() => {
      isClickLocked.current = false
    }, 150)
  })

  const handleQuickBookingClose = () => {
    const elms = document.querySelectorAll('.custom-dropdown-btn');
    for (let i = 0; i < elms.length; i++) {
      if (elms[i].classList.toString().indexOf("active") > -1) {
        elms[i].click();
      }
    }
    
    modalRef.current.focus();
  }

  const handleOnFocus = useRef(e => {
    // prevent child focus event to bubble up multiple times needlessly
    if (isFocused.current) return

    lockdownClick.current()
    isFocused.current = true
    setIsModalOpen(true)
    onModalOpenRef.current(true)
  })

  const handleOnBlur = useRef(e => {
    if (
      e.relatedTarget === null &&
      e.target &&
      [
        styles.select,
        'custom-dropdown-sub-item'
      ].some(c => e.target.classList.contains(c))
    ) {
      return false
    }

    if (
      e.relatedTarget === null &&
      e.target &&
      [
        'custom-dropdown-btn',
        'custom-dropdown-item',
      ].some(c => e.target.classList.contains(c))
    ) {
      lockdownClick.current()
      isFocused.current = false
      setIsModalOpen(false)
      onModalOpenRef.current(false)
      return
    }

    if (
      e.relatedTarget === null ||
      !(
        [
          styles.quickBookingModal,
          styles.selectWrapper,
          styles.select,
          styles.bookBtn,
          'custom-dropdown-btn',
          'custom-dropdown-item',
          'custom-dropdown-sub-item'
        ].some(c => e.relatedTarget.classList.contains(c)) ||
        ['reset-filters-btn', 'quick-book-btn'].includes(e.relatedTarget.id)
      )
    ) {
      lockdownClick.current()
      isFocused.current = false
      setIsModalOpen(false)
      onModalOpenRef.current(false)
    }
  })

  const handleOnClick = useCallback(() => {
    if (isFocused.current && !isClickLocked.current) {
      setIsModalOpen(isOpened => !isOpened)
      onModalOpenRef.current(!isModalOpen)
    }
  }, [isModalOpen])

  return (
    <div onFocus={handleOnFocus.current} onBlur={handleOnBlur.current}>
      <div
        id="quick-book-btn"
        className={`inline-block cursor-pointer btn-primary flex flex-row items-center relative ${className}`}
        onClick={handleOnClick}
        tabIndex={0}
      >
        <span>Fast Booking</span>
        <img src={caret_down} alt="Caret Down"></img>
      </div>
      <div
        className={`${isModalOpen ? styles.modalOpen : styles.modalClose} ${styles.quickBookingModal} ${modalClass}`}
        ref={modalRef}
        tabIndex={-1}
      >
        <QuickBookingModal modalState={isModalOpen} onClose={handleQuickBookingClose} onVaxxedClick={(val) => {
          setBookingLink(val)
          setIsVaxxedOpen(true)
        }} />
      </div>
      <Modal
          className={styles.vaxxedModal}
          backgroundClassName={styles.vaxxedModalBackground}
          closeClassName={styles.vaxxedModalClose}
          open={isVaxxedOpen}
          handleClose={() => setIsVaxxedOpen(false)}
          >
          <div className={styles.vaxxedModalWrapper}>
            <p>Only patrons who meet any ONE of the following criteria will be allowed to this screening:</p>
            <ul>
                <li>1. Fully vaccinated</li>
                <li>2. Recovered from COVID-19 within the last 180 days after infection with valid memo.</li>
                <li>3. Medically ineligible for all COVID-19 vaccines with valid doctor-certified memo.</li>
                <li>4. Children aged 12 years and below (i.e. born in or after 2010)</li>
            </ul>
            <p>By clicking CONFIRM, you agree that you and all members of your group meet any of the criteria above.</p>
            { bookingLink &&
              <Link className={`${styles.btnModalConfirm} block btn-primary relative`}
                    to={bookingLink}
                    target="_blank">
                  CONFIRM
              </Link>
            }
          </div>
      </Modal>
    </div>
  )
}

export default QuickBookingButton
