import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import Link from '../components/link'
import logo from '../images/icon.png'
import styles from './footer.module.css'
import Image from './image'

import MailingSubscription from './mailing-subscription'

const Footer = () => {
  const footer = useFooter()

  return (
    <footer>
      <div className="container">
        <MailingSubscription mailingListID={footer.mailingListID} />
      </div>
      <div
        className={`container ${styles.footerWrapper} flex flex-col md:flex-row justify-between`}
      >
        <div
          className={`${styles.logoWrapper} flex order-last flex-none items-center justify-center md:hidden md:order-first lg:flex`}
        >
          <Link to="/">
            {footer.icon ? (
              <Image
                className={`${styles.logo}`}
                fluid={
                  footer.icon.childImageSharp &&
                  footer.icon.childImageSharp.fluid
                }
              />
            ) : (
              <img className={styles.logo} src={logo} alt="logo" />
            )}
          </Link>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.addressWrapper}>
            <address
              className={styles.address}
              dangerouslySetInnerHTML={{ __html: footer.addressHtml }}
            ></address>
            <a
              target="__blank"
              href={`https://goo.gl/maps/${footer.googlePlaceID}`}
            >
              See on <u>Google Maps</u>
            </a>
          </div>
          <div
            className={styles.scheduleWrapper}
            dangerouslySetInnerHTML={{ __html: footer.schedulesHtml }}
          />
          <div
            className={styles.contactWrapper}
            dangerouslySetInnerHTML={{ __html: footer.contactsHtml }}
          />
          <div className={styles.hyperlinkWrapper}>
            {footer.anchors.map((a, i) => (
              <Link key={i} to={a.hyperlink}>
                {a.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

const useFooter = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: { fields: { slug: { eq: "/footer/" } } }) {
          edges {
            node {
              frontmatter {
                schedulesHtml
                icon {
                  childImageSharp {
                    fluid(quality: 90, fit: CONTAIN) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                addressHtml
                googlePlaceID
                contactsHtml
                anchors {
                  name
                  hyperlink
                }
                mailingListID
              }
            }
          }
        }
      }
    `
  )

  if (allMarkdownRemark.edges.length) {
    const {
      node: { frontmatter },
    } = allMarkdownRemark.edges[0]

    return frontmatter
  }

  return null
}

export default Footer
