/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import './layout.css'
import 'dayjs/locale/en-sg'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { useScreenSize } from '../hooks/screen-size'
import Footer from './footer'
import Header from './header'

dayjs.locale('en-SG')
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

const Layout = ({ children }) => {
  const { mainNavExpandedHeight } = useScreenSize()
  const [mainStyle, setMainStyle] = useState({
    opacity: 0,
  })

  useEffect(() => {
    setMainStyle({
      paddingTop: `${mainNavExpandedHeight}px`,
      opacity: 1,
    })
  }, [mainNavExpandedHeight])

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="page-container">
          <Header siteTitle={data.site.siteMetadata.title} />
          <main className="main" style={mainStyle}>
            {children}
          </main>
          <Footer></Footer>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
