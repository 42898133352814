import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import { isUndefined } from 'lodash'
import Axios from 'axios'
import shortid from 'shortid'

import { classes } from '../util/components'

const svgMap = {}

const Image = ({ title, fluid, image, style, className, alt, ...data }) => {
  let _fluid = fluid
  let _alt = alt || title

  if (image) {
    if (
      !image.childImageSharp &&
      image.extension === 'svg' &&
      image.publicURL
    ) {
      const imageId = shortid.generate()

      const injectSvgToContainer = () => {
        const containerEl = window.document.getElementById(imageId)

        if (containerEl) {
          containerEl.innerHTML = svgMap[image.publicURL]

          if (!isUndefined(data.width)) {
            containerEl.firstElementChild.setAttribute('width', data.width)
          }
          if (!isUndefined(data.height)) {
            containerEl.firstElementChild.setAttribute('height', data.height)
          }
          if (className) {
            containerEl.firstElementChild.setAttribute('class', className)
          }
        }
      }

      if (svgMap[image.publicURL]) {
        injectSvgToContainer()
      } else if (typeof window !== 'undefined') {
        Axios.get(image.publicURL).then(res => {
          if (res.data) {
            svgMap[image.publicURL] = res.data
            injectSvgToContainer()
          }
        })
      }

      return (
        <div className="w-full" id={imageId}>
          <img
            title={title}
            style={style}
            className={classes('object-cover w-full', className)}
            type="image/svg+xml"
            src={image.publicURL}
            alt={_alt}
          />
        </div>
      )
    }

    _fluid = image.childImageSharp && image.childImageSharp.fluid
  }

  if (!!_fluid && typeof _fluid === 'object') {
    // check for art-directing
    if (Array.isArray(_fluid) && _fluid.some(e => typeof e === 'string')) {
      return (
        <img
          title={title}
          style={style}
          className={classes('object-cover w-full', className)}
          src={_fluid.find(e => typeof e === 'string')}
          alt={_alt}
        />
      )
    }

    return (
      <Img
        title={title}
        alt={_alt}
        style={style}
        fluid={_fluid}
        className={className}
        {...data}
      />
    )
  }

  if (!!_fluid && typeof _fluid === 'string')
    return (
      <img
        title={title}
        style={style}
        className={classes('object-cover w-full', className)}
        src={_fluid}
        alt={_alt}
      />
    )

  return null
}

Image.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  fluid: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  image: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default Image
