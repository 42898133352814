import { useState, useEffect } from 'react'
import { useScreenSize } from './screen-size'

export function useMainNavHeight(isSticky = false) {
  const { mainNavExpandedHeight, mainNavCollapsedHeight } = useScreenSize()

  const [mainNavHeight, setMainNavHeight] = useState(
    () => mainNavExpandedHeight
  )

  useEffect(() => {
    const handleScrollChange = () => {
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )

      setMainNavHeight(
        Math.max(mainNavExpandedHeight - scrollTop, mainNavCollapsedHeight)
      )
    }

    handleScrollChange()

    window.addEventListener('scroll', handleScrollChange)
    window.addEventListener('resize', handleScrollChange)

    return () => {
      window.removeEventListener('scroll', handleScrollChange)
      window.removeEventListener('resize', handleScrollChange)
    }
  }, [mainNavCollapsedHeight, mainNavExpandedHeight])

  return mainNavHeight
}
