import { useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash'

const CACHE_EXPIRY = 10

let cachedSessions = {
  'golden-mile-tower': {
    sessions: null,
    isFetchOngoing: false,
  },
  // 'riverside-point': {
  //   sessions: null,
  //   isFetchOngoing: false,
  // },
  'cineleisure': {
    sessions: null,
    isFetchOngoing: false,
  },
  // 'picturehouse-xtra': {
  //   sessions: null,
  //   isFetchOngoing: false,
  // },
  'all': {
    sessions: null,
    isFetchOngoing: false,
  },
}
let lastCallInSec = 0

const promiseQueue = []

const cacheSessions = (sessions, key) => {
  cachedSessions[key].sessions = sessions
  cachedSessions[key].isFetchOngoing = true
  lastCallInSec = Date.now() / 1000
}

const isCacheExpired = () => Date.now() / 1000 - lastCallInSec > CACHE_EXPIRY

const clearQueue = () => {
  promiseQueue.forEach(func => typeof func === 'function' && func())
  promiseQueue.splice(0, promiseQueue.length)
}

const slugify = (text) => {
  if (!text) return ''
  return text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

export function useVeeziWebSessions(options, location) {
  const { filmId, startDate, endDate } = options || {}
  const key = slugify(location) || 'golden-mile-tower'
  const apiKey = {
    'golden-mile-tower': 'sVJR4rtFak-ZQvm87lwoIA2',
    // 'riverside-point': 'HMjBo8W8vEu_OGN2kSow-A2',
    'cineleisure': '8xhbsjcv7n4yw5d9kjrmzk9ecg',
    // 'picturehouse-xtra': '8xhbsjcv7n4yw5d9kjrmzk9ecg',
  }

  const [veeziWebSessions, setVeeziWebSessions] = useState([])

  const endDateTime = endDate && endDate.getTime()
  const startDateTime = startDate && startDate.getTime()

  const sessionFilter = useCallback(
    j =>
      (filmId === undefined || (filmId !== null && j.FilmId === filmId)) &&
      (!startDateTime ||
        dayjs(j.PreShowStartTime).isSameOrAfter(startDateTime)) &&
      (!endDateTime || dayjs(j.PreShowStartTime).isBefore(endDateTime)),
    [filmId, startDateTime, endDateTime, key]
  )
  useEffect(() => {
    let ignore = false

    if (!apiKey[key]) {
      setVeeziWebSessions([])
      return () => {
        ignore = true
      }
    }

    const doFetch = () => {
      cachedSessions[key].isFetchOngoing = true

      const req = new Request('https://api.us.veezi.com/v1/websession/', {
        headers: { VeeziAccessToken: apiKey[key] },
      })

      fetch(req).then(res => {
        cachedSessions[key].isFetchOngoing = false

        if (res.ok) {
          return res.json().then(json => {
            cacheSessions(json, key)
            clearQueue()

            if (!ignore) {
              setVeeziWebSessions(cachedSessions[key].sessions)
            }
          })
        }

        return res.text().then(text => {
          throw text
        })
      })
    }

    if (!ignore && cachedSessions[key].sessions) {
      setVeeziWebSessions(cachedSessions[key].sessions)
    }

    if (isCacheExpired() || !cachedSessions[key].sessions) {
      if (!cachedSessions[key].isFetchOngoing) {
        doFetch()
      } else {
        promiseQueue.push(() => {
          if (!ignore) {
            setVeeziWebSessions(cachedSessions[key].sessions)
          }
        })
      }
    }

    return () => {
      ignore = true
    }
  }, [sessionFilter])

  return veeziWebSessions
}

export function useVeeziWebSessionsAll(options) {
  const { filmId, startDate, endDate } = options || {}
  const key = 'all'
  const apiKey = {
    'golden-mile-tower': 'sVJR4rtFak-ZQvm87lwoIA2',
    // 'riverside-point': 'HMjBo8W8vEu_OGN2kSow-A2',
    'cineleisure': '8xhbsjcv7n4yw5d9kjrmzk9ecg',
    // 'picturehouse-xtra': '8xhbsjcv7n4yw5d9kjrmzk9ecg',
  }

  const [veeziWebSessions, setVeeziWebSessions] = useState([])

  const endDateTime = endDate && endDate.getTime()
  const startDateTime = startDate && startDate.getTime()

  const sessionFilter = useCallback(
    j =>
      (filmId === undefined || (filmId !== null && j.FilmId === filmId)) &&
      (!startDateTime ||
        dayjs(j.PreShowStartTime).isSameOrAfter(startDateTime)) &&
      (!endDateTime || dayjs(j.PreShowStartTime).isBefore(endDateTime)),
    [filmId, startDateTime, endDateTime, key]
  )
  useEffect(() => {
    let ignore = false

    if (!key) {
      setVeeziWebSessions([])
      return () => {
        ignore = true
      }
    }

    const doFetch = () => {
      cachedSessions[key].isFetchOngoing = true

      const promiseArr = []
      Object.keys(apiKey).map(location => {
        const req = new Request('https://api.us.veezi.com/v1/websession/', {
          headers: { VeeziAccessToken: apiKey[location] },
        })
        promiseArr.push(fetch(req)
          .then(res => {
            if (res.ok) {
              return res.json().then(json => {
                return json
              })
            }
            return []
          }).catch(err => {
            return []
          })
        )
      })
      Promise.all(promiseArr)
        .then(res => {
          cachedSessions[key].isFetchOngoing = false
          const result = [].concat.apply([], res);
          cacheSessions(uniqBy(result, 'Id'), key)
          clearQueue()

          if (!ignore) {
            setVeeziWebSessions(cachedSessions[key].sessions)
          }
        })
    }

    if (!ignore && cachedSessions[key].sessions) {
      setVeeziWebSessions(cachedSessions[key].sessions)
    }

    if (isCacheExpired() || !cachedSessions[key].sessions) {
      if (!cachedSessions[key].isFetchOngoing) {
        doFetch()
      } else {
        promiseQueue.push(() => {
          if (!ignore) {
            cachedSessions[key] && setVeeziWebSessions(cachedSessions[key].sessions)
          }
        })
      }
    }

    return () => {
      ignore = true
    }
  }, [sessionFilter])
  return veeziWebSessions
}
