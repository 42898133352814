import React from 'react'
import PropTypes from 'prop-types'

import styles from './hamburger-icon.module.css'

const HamburgerIcon = ({ isOpened }) => (
  <div
    className={`${styles.hamburger} ${isOpened ? styles.hamburgerOpen : ''}`}
  >
    <span></span>
    <span></span>
  </div>
)

HamburgerIcon.propTypes = {
  isOpened: PropTypes.bool,
}

HamburgerIcon.defaultProps = {
  isOpened: false,
}

export default HamburgerIcon
