import PropTypes from 'prop-types'
import React from 'react'

import MainNav from './main-nav'
import SEO from './seo'

const Header = ({ siteTitle }) => (
  <header>
    <SEO title={siteTitle} />
    <MainNav />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  onNavResize: PropTypes.func,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
