exports.range = (start, end) => {
  const vals = []
  for (let i = start; i <= end; i++) {
    vals.push(i)
  }

  return vals
}

exports.flatten = (arr, level = 1) => {
  function flattenRecursively(_arr, _level) {
    if (_level <= 0) return _arr

    return _arr.reduce(
      (acc, val) =>
        Array.isArray(val)
          ? acc.concat(flattenRecursively(val, _level - 1))
          : acc.concat(val),
      []
    )
  }

  return flattenRecursively(arr, level)
}

exports.arrayToMap = (arr, getKey) => {
  if (!arr || !Array.isArray(arr)) return null

  return arr.reduce((map, a) => {
    const k = getKey(a)
    map[k] = a

    return map
  }, {})
}

exports.groupBy = (arr, getKey) => {
  if (!arr || !Array.isArray(arr)) return null

  return arr.reduce((map, a) => {
    const k = getKey(a)

    if (!k) return map

    if (map[k]) {
      map[k] = [a, ...map[k]]
    } else {
      map[k] = [a]
    }

    return map
  }, {})
}
