import React, { createRef, useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'

import iconClose from '../images/icon_close.svg'
import { classes } from '../util/components'
import styles from './modal.module.css'

const Modal = ({
  children,
  open,
  handleClose,
  className,
  backgroundClassName,
  closeClassName,
}) => {
  const escBtn = createRef()
  const [bgClass, setBgClass] = useState(styles.modalBgClose)

  const checkDocumentKeyDown = useCallback(
    event => {
      if (
        event.key === 'Escape' ||
        event.key === 'Esc' ||
        event.keyCode === 27
      ) {
        handleClose()
      }
    },
    [handleClose]
  )

  const addKeyDownListener = useCallback(() => {
    setTimeout(() => {
      document.addEventListener('keydown', checkDocumentKeyDown)
    })
  }, [checkDocumentKeyDown])

  const removeKeyDownListener = useCallback(() => {
    setTimeout(() => {
      document.removeEventListener('keydown', checkDocumentKeyDown)
    })
  }, [checkDocumentKeyDown])

  useEffect(() => {
    const currentEscBtn = escBtn.current

    if (open) {
      currentEscBtn.focus()
      addKeyDownListener()
      setBgClass(styles.modalBgOpen)
    } else {
      currentEscBtn.blur()
      removeKeyDownListener()
      setBgClass(styles.modalBgClose)
    }

    return () => {
      currentEscBtn.blur()
      removeKeyDownListener()
    }
  }, [addKeyDownListener, escBtn, open, removeKeyDownListener])

  return (
    <>
      <Helmet bodyAttributes={{ class: open ? 'overflow-hidden' : '' }} />
      <div
        className={classes(
          styles.modalBackground,
          backgroundClassName,
          'flex justify-center items-center testetstetetse',
          bgClass
        )}
        onClick={handleClose}
        aria-modal="true"
      >
        <div
          className={classes(styles.modal, className)}
          onClick={e => e.stopPropagation()}
        >
          <span
            ref={escBtn}
            className={classes(styles.btnClose, closeClassName)}
            onClick={handleClose}
            tabIndex="-1"
          >
            <img src={iconClose} alt="close" />
          </span>
          {children}
        </div>
      </div>
    </>
  )
}

Modal.defaultProps = {
  open: false,
  handleClose: () => {},
}

export default Modal
