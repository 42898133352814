import { useState, useEffect } from 'react'

import tailwindConfig from '../../tailwind.config'

export const MAIN_NAV_EXPANDED_HEIGHTS = {
  xl: 246,
  lg: 246,
  md: 246,
  sm: 140,
  xs: 140,
}

export const MAIN_NAV_COLLAPSED_HEIGHTS = {
  xl: 132,
  lg: 132,
  md: 132,
  sm: 55,
  xs: 55,
}

const PX_RE = /^([0-9]+)px$/
const BREAKPOINT_NAMES = ['xs', ...Object.keys(tailwindConfig.theme.screens)]
const BREAKPOINTS = [
  0,
  ...Object.keys(tailwindConfig.theme.screens).map(k =>
    parseInt(PX_RE.exec(tailwindConfig.theme.screens[k])[1])
  ),
]

const getBreakpoint = windowWidth => {
  if (windowWidth === null) return null

  const i = BREAKPOINTS.findIndex(b => windowWidth < b) - 1
  if (i >= 0) {
    return BREAKPOINT_NAMES[i]
  }

  return BREAKPOINT_NAMES[BREAKPOINT_NAMES.length - 1]
}

export function useScreenSize() {
  const [screenSize, setScreenSize] = useState(() =>
    getBreakpoint(typeof window === 'undefined' ? null : window.innerWidth)
  )

  useEffect(() => {
    const handleResize = () => {
      const size = getBreakpoint(window.innerWidth)
      setScreenSize(size)
    }

    handleResize() // done for proper hydration

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    screenSize,
    mainNavExpandedHeight: MAIN_NAV_EXPANDED_HEIGHTS[screenSize],
    mainNavCollapsedHeight: MAIN_NAV_COLLAPSED_HEIGHTS[screenSize],
  }
}

export function useIsMobile() {
  const { screenSize } = useScreenSize()

  return ['xs', 'sm'].includes(screenSize)
}

export function useIsMobileAndTablet() {
  const { screenSize } = useScreenSize()

  return ['xs', 'sm', 'md'].includes(screenSize)
}
