import { graphql, navigate, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react'

import Link from '../components/link'
import QuickBookingButton from '../components/quick-booking-button'
import { useMainNavHeight } from '../hooks/main-nav-height'
import { useIsMobile, useScreenSize } from '../hooks/screen-size'
import caretDown from '../images/caret_down.svg'
import { classes } from '../util/components'
import HamburgerIcon from './hamburger-icon'
import styles from './main-nav.module.css'

const LINE1_OFFSET = 10
const LINE2_OFFSET = 50
const LINE1_OFFSET_MOBILE = 110
const LINE2_OFFSET_MOBILE = 150
const COLUMN_TOP_OFFSETS = [
  82,
  61,
  39,
  166.8,
  145.9,
  123.9,
  103.4,
  83.4,
  60.1,
  41.7,
  17.6,
  0.0,
]

const LETTER_OFFSETS = [
  0,
  0,
  0,
  0,
  41.7,
  42.5,
  42.1,
  41.7,
  42.5,
  41.7,
  42.5,
  41.7,
]

const MENU_ITEMS = [
  {
    id: 'films',
    label: 'Films & Events',
    items: [
      { label: 'See All Films', link: '/films' },
      // { label: 'Accessible Screenings', link: '/films#accessibility' },
      { label: 'See All Events', link: '/events' },
      // { label: 'Accessible Events', link: '/events#accessibility' },
    ],
  },
  // {
  //   id: 'projector-plus',
  //   label: 'Projector Plus',
  //   items: [],
  // },
  {
    id: 'locations',
    label: 'Locations',
    link: '/about-us',
    items: [
      { label: 'Golden Mile Tower', link: '/golden-mile-tower' },
      { label: 'Cineleisure', link: '/cineleisure' },
      // { label: 'Picturehouse', link: '/picturehouse' },
      // { label: 'Picturehouse Xtra', link: '/picturehouse-xtra' },
    ],
  },
  {
    id: 'bar',
    label: 'Bar',
    link: '/intermission-bar',
    items: [
      { label: 'Intermission Bar', link: '/intermission-bar' },
      { label: 'No Spoilers Dining', link: '/no-spoilers-dining' },
      { label: 'Promos', link: '/promos' },
      // { label: 'Menu', link: '/intermission-bar#menu' },
      { label: 'Hire the Bar', link: '/venues/bar-foyer' },
    ],
  },
  {
    id: 'venue-hire',
    label: 'Venue Hire',
    link: '/venue-hire',
    items: [
      { label: 'Our Spaces', link: '/venue-hire' },
      { label: 'Rental Rates & Packages', link: '/rental-rates' },
      { label: 'Enquiry Form', link: '/rental-rates/#request' },
      { label: 'Download Brochure', link: '' },
    ],
  },
  {
    id: 'membership',
    label: 'Membership & Gifts',
    link: '/membership',
    items: [
      { label: 'Membership', link: '/membership' },
      { label: 'Gifts', link: '/gifts' },
    ],
  },
  {
    id: 'faq',
    label: 'FAQ',
    link: '/faq',
  },
]

const useNavLinks = () => {
  const { navLinks, venueHire } = useStaticQuery(
    graphql`
      {
        navLinks: allMarkdownRemark(
          filter: { fields: { collection: { eq: "nav-links" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                parent
                links {
                  linkURL
                  title
                }
              }
            }
          }
        }
        venueHire: markdownRemark(fields: { slug: { eq: "/venue-hire/" } }) {
          frontmatter {
            brochureForm {
              publicURL
            }
          }
        }
      }
    `
  )

  const navLinksRef = useRef({
    navLinks: navLinks.edges.reduce((linkMap, e) => {
      const title = e.node.frontmatter.parent

      if (!linkMap[title]) linkMap[title] = []

      linkMap[title] = linkMap[title].concat(
        (e.node.frontmatter.links || []).map(l => ({
          label: l.title,
          link: l.linkURL,
        }))
      )

      return linkMap
    }, {}),
    brochureForm:
      venueHire.frontmatter &&
      venueHire.frontmatter.brochureForm &&
      venueHire.frontmatter.brochureForm.publicURL,
  })

  return navLinksRef.current
}

const getLetterStyle = (
  screenSize,
  transformRangePosition,
  letter,
  lineOffset,
  i
) => {
  const columnTopOffset = COLUMN_TOP_OFFSETS[letter]
  const letterOffset = LETTER_OFFSETS[letter]
  const y =
    transformRangePosition * (columnTopOffset - lineOffset + i * letterOffset)
  let opacity = 1

  if (i === 0) {
    opacity = opacity + transformRangePosition * (1 - opacity)
  } else if (transformRangePosition > 0) {
    opacity = opacity - transformRangePosition * opacity - i * 0.05
  }

  return {
    transform: `translateY(${y ? -y : 0}px)`,
    opacity: opacity ? opacity : 1,
  }
}

const ListItem = forwardRef((props, ref) => (
  <li
    ref={ref}
    className={`${styles.dropdownItem} ${props.className || ''}`}
    onKeyDown={props.onKeyDown}
    tabIndex={-1}
  >
    {props.item.link ? (
      <Link to={props.item.link} onClick={props.onClick} tabIndex={-1}>
        {props.item.label}
      </Link>
    ) : (
        props.item.label
      )}
  </li>
))

const MenuItems = ({
  expandedItems,
  navLinks,
  forceCloseNav,
  handleKeyDown,
  itemWithFocus,
  isOpened,
  toggleExpanded,
}) => {
  const isMobile = useIsMobile()

  return (
    <>
      {MENU_ITEMS.map((item, i) => {
        const itemRef = createRef()
        const subItemRefs = []
        const subItems = [
          ...(item.items || []),
          ...(navLinks[item.label] || []),
        ].length ? (
            <>
              {item.items.map((subItem, a) => {
                const ref = createRef()
                subItemRefs.push(ref)

                return (
                  <ListItem
                    ref={ref}
                    key={a}
                    item={subItem}
                    onClick={forceCloseNav}
                    onKeyDown={handleKeyDown(
                      item,
                      itemRef,
                      subItemRefs,
                      subItemRefs.length - 1
                    )}
                  />
                )
              })}
              {navLinks[item.label] ? (
                isMobile ? (
                  (navLinks[item.label] || []).map((subItem, a) => {
                    const ref = createRef()
                    subItemRefs.push(ref)

                    return (
                      <ListItem
                        ref={ref}
                        className={classes(
                          (item.items || []).length > 0 &&
                          styles.dropdownItemTheme
                        )}
                        key={a}
                        item={subItem}
                        onClick={forceCloseNav}
                        onKeyDown={handleKeyDown(
                          item,
                          itemRef,
                          subItemRefs,
                          subItemRefs.length - 1
                        )}
                      />
                    )
                  })
                ) : (
                    <li
                      className={classes(
                        (item.items || []).length > 0 && styles.dropdownItemTheme,
                        styles.dropdownItemMobile
                      )}
                    >
                      <ul
                        className={classes(
                          (item.items || []).length > 0 &&
                          styles.dropdownThemeWrapper
                        )}
                      >
                        {(navLinks[item.label] || []).map((subItem, a) => {
                          const ref = createRef()
                          subItemRefs.push(ref)

                          return (
                            <ListItem
                              key={a}
                              ref={ref}
                              item={subItem}
                              onClick={forceCloseNav}
                              onKeyDown={handleKeyDown(
                                item,
                                itemRef,
                                subItemRefs,
                                subItemRefs.length - 1
                              )}
                            />
                          )
                        })}
                      </ul>
                    </li>
                  )
              ) : (
                  ''
                )}
            </>
          ) : null

        return (
          <li
            key={i}
            tabIndex={0}
            ref={itemRef}
            className={classes(
              styles.item,
              expandedItems.includes(item.id) && styles.expanded,
              itemWithFocus === item.id && styles.hasFocus
            )}
            onKeyDown={handleKeyDown(item, itemRef, subItemRefs)}
            aria-label={item.label}
            style={
              isOpened
                ? {
                  transitionDelay: `${0.1 * (i + 2)}s`,
                }
                : null
            }
          >
            <div className="underline-hover flex flex-row items-center">
              {item.link ? (
                <Link
                  className={styles.itemLink}
                  to={item.link}
                  onClick={forceCloseNav}
                  tabIndex={-1}
                >
                  {item.label}
                </Link>
              ) : (
                  <span className={styles.itemLink}>{item.label}</span>
                )}
              {subItems ? (
                <div
                  className={styles.caret}
                  onClick={() => toggleExpanded(item)}
                >
                  <img src={caretDown} alt="Caret Down" />
                </div>
              ) : null}
            </div>
            {subItems ? (
              <div className={styles.dropdownWrapper}>
                <ul className={styles.dropdown}>{subItems}</ul>
              </div>
            ) : null}
          </li>
        )
      })}
      <li
        className={classes(styles.item)}
        style={{
          marginRight: '30px'
        }}
        tabIndex={0}
      >
        <Link className={styles.itemLink}
              style={{ display: 'block' }}
              to={'/films#search'}
              onClick={forceCloseNav}>
          <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.427 15.427a8.5 8.5 0 112-2l5.585 5.585c.55.55.546 1.431 0 1.976l-.023.023a1.398 1.398 0 01-1.976 0l-5.585-5.585-.001.001zM8.5 15a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
              fill="#FFF"
            />
          </svg>
        </Link>
      </li>
      <li
        className={classes(styles.item, styles.fastBookingBtn)}
        style={
          isOpened
            ? {
              transitionDelay: `${0.1}s`,
            }
            : null
        }
        tabIndex={0}
      >
        {/*<QuickBookingButton className={styles.quickBookBtn} />*/}
      </li>
      {
        !isOpened ? (
          <QuickBookingButton className={styles.quickBookBtn} />
        ) : null
      }
    </>
  )
}

const MainNav = () => {
  const mainNavHeight = useMainNavHeight()
  const {
    screenSize,
    mainNavExpandedHeight,
    mainNavCollapsedHeight,
  } = useScreenSize()
  const [isOpened, setIsOpened] = useState(false)
  const [isQuickBookOpened, setIsQuickBookOpened] = useState(false)
  const [mainNavStyle, setMainNavStyle] = useState(null)
  const [expandedItems, setExpandedItems] = useState([])
  const [line1Offset, setLine1Offset] = useState(0)
  const [line2Offset, setLine2Offset] = useState(0)
  const [itemWithFocus, setItemWithFocus] = useState(null)
  const { navLinks, brochureForm } = useNavLinks()
  const itemsRef = React.createRef()

  useEffect(() => {
    const venueHireMenu = MENU_ITEMS.find(i => i.id === 'venue-hire')
    if (brochureForm && venueHireMenu && venueHireMenu.items) {
      const brochureLink = venueHireMenu.items.find(
        i => i.label === 'Download Brochure'
      )
      if (brochureLink) brochureLink.link = brochureForm
    }
  }, [brochureForm])

  const toggleNavigationBar = useCallback(
    val => {
      setIsOpened(val)

      if (val) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
        // reset the menu
        itemsRef.current.scrollTo(0, 0)
        setExpandedItems([])
      }
    },
    [itemsRef]
  )

  const handleHamburgerClick = useCallback(
    () => toggleNavigationBar(!isOpened),
    [isOpened, toggleNavigationBar]
  )
  const forceCloseNav = useCallback(() => toggleNavigationBar(false), [
    toggleNavigationBar,
  ])

  const mainNavHeightUpdated =
    isOpened || isQuickBookOpened ? mainNavCollapsedHeight : mainNavHeight

  const positionFactor =
    (mainNavExpandedHeight - mainNavHeightUpdated) /
    (mainNavExpandedHeight - mainNavCollapsedHeight)

  useEffect(() => {
    setMainNavStyle({
      maxHeight: `${mainNavHeightUpdated}px`,
      borderBottom: `1px solid rgba(200, 200, 200, ${positionFactor})`,
    })
  }, [mainNavHeightUpdated, positionFactor])

  useEffect(() => {
    const isTablet =
      !!itemsRef.current && itemsRef.current.getBoundingClientRect().top < 0

    setLine1Offset(
      ['xs', 'sm'].includes(screenSize)
        ? LINE1_OFFSET_MOBILE
        : isTablet
          ? LINE1_OFFSET + mainNavCollapsedHeight / 1.4
          : LINE1_OFFSET
    )

    setLine2Offset(
      ['xs', 'sm'].includes(screenSize)
        ? LINE2_OFFSET_MOBILE
        : isTablet
          ? LINE2_OFFSET + mainNavCollapsedHeight / 1.4
          : LINE2_OFFSET
    )
  }, [itemsRef, screenSize, mainNavCollapsedHeight])

  let letter = 0

  const toggleExpanded = useCallback(
    item => {
      if (expandedItems.includes(item.id)) {
        expandedItems.splice(expandedItems.indexOf(item.id), 1)
        setExpandedItems([...expandedItems])
      } else {
        setExpandedItems([item.id, ...expandedItems])
      }
    },
    [expandedItems]
  )

  const handleKeyDown = useCallback(
    (item, itemRef, subItemRefs, currentIndex = null) => event => {
      if (event.keyCode === 13 && item.link) {
        setItemWithFocus(null)
        navigate(item.link)
      } else if (event.keyCode === 40) {
        setItemWithFocus(item.id)
        event.preventDefault()
        event.stopPropagation()

        if (currentIndex === null && subItemRefs.length) {
          subItemRefs[0].current.focus()
        } else if (
          currentIndex !== null &&
          subItemRefs.length > currentIndex + 1
        ) {
          subItemRefs[currentIndex + 1].current.focus()
        }
      } else if (event.keyCode === 38) {
        event.preventDefault()
        event.stopPropagation()

        if (currentIndex > 0) {
          subItemRefs[currentIndex - 1].current.focus()
        } else if (currentIndex === 0) {
          itemRef.current.focus()
        }
      } else if (event.keyCode === 9) {
        setItemWithFocus(null)
        event.stopPropagation()
      }
    },
    []
  )

  return (
    <nav className={styles.mainNav} style={mainNavStyle}>
      <div
        className={`${styles.logo} z-50`}
        style={
          isOpened
            ? {
              visibility: 'hidden',
            }
            : null
        }
      >
        <Link to="/" aria-label="The Projector">
          <svg
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 329.6 360"
          >
            <path
              className={`${styles.logoLetter} ${styles.theT}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line1Offset,
                0
              )}
              d="M9.8 86.4H0v-2.9h22.9v2.9h-9.8V110H9.8V86.4z"
            />
            <path
              className={`${styles.logoLetter} ${styles.theH}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line1Offset,
                0
              )}
              d="M38 62.6h3.3v10.9h15.3V62.6h3.3v26.5h-3.3V76.4H41.3v12.7H38V62.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.theE}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line1Offset,
                0
              )}
              d="M78.9 40.6h20v2.9H82.1v8h12.6v2.9H82.1v9.8h17.4V67H78.9V40.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorP1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line2Offset,
                0
              )}
              d="M.3 166.8h10.9c7.3 0 11.3 4.4 11.3 9.9s-4 9.9-11.4 9.9H3.6v6.6H.3v-26.4zm11.2 16.9c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7H3.6v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                0
              )}
              d="M37.8 145.9h10.8c7.5 0 11.4 4.5 11.4 9.9 0 3.9-2.2 7.3-6 8.9l6.3 7.7H56l-5.4-6.7c-1 .1-4 .1-4.8.1H41v6.6h-3.3v-26.5zm11.1 17c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7H41v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line2Offset,
                1
              )}
              d="M37.8 187.6h10.8c7.5 0 11.4 4.5 11.4 9.9 0 3.9-2.2 7.3-6 8.9l6.3 7.7H56l-5.4-6.7c-1 .1-4 .1-4.8.1H41v6.6h-3.3v-26.5zm11.1 17c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7H41v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                0
              )}
              d="M103 137.5c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8-.1 13.8 6.1 13.8 13.6zm-3.5 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7 5.9-.1 10.4-4.9 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                1
              )}
              d="M103 180c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8 0 13.8 6.1 13.8 13.6zm-3.5 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7s10.4-4.9 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line2Offset,
                2
              )}
              d="M103 222.5c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8 0 13.8 6.1 13.8 13.6zm-3.5 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7s10.4-4.9 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorJ1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                0
              )}
              d="M120.6 123.5c1.5 2.8 3.7 3.8 5.9 3.8 3.8 0 6.9-2.3 6.9-8.2v-15.7h3.3v15.9c0 7.8-4.6 11-10.1 11-3.5 0-6.7-1.7-8.3-4.9l2.3-1.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorJ1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                1
              )}
              d="M120.6 165.6c1.5 2.8 3.7 3.8 5.9 3.8 3.8 0 6.9-2.3 6.9-8.2v-15.7h3.3v15.9c0 7.8-4.6 11-10.1 11-3.5 0-6.7-1.7-8.3-4.9l2.3-1.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorJ1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                2
              )}
              d="M120.6 207.7c1.5 2.8 3.7 3.8 5.9 3.8 3.8 0 6.9-2.3 6.9-8.2v-15.7h3.3v15.9c0 7.8-4.6 11-10.1 11-3.5 0-6.7-1.7-8.3-4.9l2.3-1.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorJ1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line2Offset,
                3
              )}
              d="M120.6 249.8c1.5 2.8 3.7 3.8 5.9 3.8 3.8 0 6.9-2.3 6.9-8.2v-15.7h3.3v15.9c0 7.8-4.6 11-10.1 11-3.5 0-6.7-1.7-8.3-4.9l2.3-1.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorE1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                0
              )}
              d="M151.8 83.4h20.1v2.9h-16.8v8h12.6v2.9h-12.6v9.8h17.4v2.9h-20.6V83.4z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorE1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                1
              )}
              d="M151.8 125.1h20.1v2.9h-16.8v8h12.6v2.9h-12.6v9.8h17.4v2.9h-20.6v-26.5z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorE1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                2
              )}
              d="M151.8 166.8h20.1v2.9h-16.8v8h12.6v2.9h-12.6v9.8h17.4v2.9h-20.6v-26.5z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorE1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                3
              )}
              d="M151.8 208.5h20.1v2.9h-16.8v8h12.6v2.9h-12.6v9.8h17.4v2.9h-20.6v-26.5z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorE1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line2Offset,
                4
              )}
              d="M151.8 250.2h20.1v2.9h-16.8v8h12.6v2.9h-12.6v9.8h17.4v2.9h-20.6v-26.5z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorC1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                0
              )}
              d="M201.5 60.1c3.6 0 6.8 1.3 9.2 3.4l-2 2.2c-2-1.8-4.4-2.7-7-2.7-6 0-10.5 4.8-10.5 10.7 0 5.8 4.5 10.7 10.4 10.7 3 0 5.8-1.2 7.7-3.3l2.1 2.2c-2.5 2.5-5.9 4-9.8 4-7.8 0-13.8-6.1-13.8-13.6-.1-7.5 5.9-13.6 13.7-13.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorC1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                1
              )}
              d="M201.5 102.6c3.6 0 6.8 1.3 9.2 3.4l-2 2.2c-2-1.8-4.4-2.7-7-2.7-6 0-10.5 4.8-10.5 10.7 0 5.8 4.5 10.7 10.4 10.7 3 0 5.8-1.2 7.7-3.3l2.1 2.2c-2.5 2.5-5.9 4-9.8 4-7.8 0-13.8-6.1-13.8-13.6-.1-7.5 5.9-13.6 13.7-13.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorC1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                2
              )}
              d="M201.5 145.1c3.6 0 6.8 1.3 9.2 3.4l-2 2.2c-2-1.8-4.4-2.7-7-2.7-6 0-10.5 4.8-10.5 10.7 0 5.8 4.5 10.7 10.4 10.7 3 0 5.8-1.2 7.7-3.3l2.1 2.2c-2.5 2.5-5.9 4-9.8 4-7.8 0-13.8-6.1-13.8-13.6-.1-7.5 5.9-13.6 13.7-13.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorC1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                3
              )}
              d="M201.5 187.6c3.6 0 6.8 1.3 9.2 3.4l-2 2.2c-2-1.8-4.4-2.7-7-2.7-6 0-10.5 4.8-10.5 10.7 0 5.8 4.5 10.7 10.4 10.7 3 0 5.8-1.2 7.7-3.3l2.1 2.2c-2.5 2.5-5.9 4-9.8 4-7.8 0-13.8-6.1-13.8-13.6-.1-7.5 5.9-13.6 13.7-13.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorC1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                4
              )}
              d="M201.5 230.1c3.6 0 6.8 1.3 9.2 3.4l-2 2.2c-2-1.8-4.4-2.7-7-2.7-6 0-10.5 4.8-10.5 10.7 0 5.8 4.5 10.7 10.4 10.7 3 0 5.8-1.2 7.7-3.3l2.1 2.2c-2.5 2.5-5.9 4-9.8 4-7.8 0-13.8-6.1-13.8-13.6-.1-7.4 5.9-13.6 13.7-13.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorC1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line2Offset,
                5
              )}
              d="M201.5 272.6c3.6 0 6.8 1.3 9.2 3.4l-2 2.2c-2-1.8-4.4-2.7-7-2.7-6 0-10.5 4.8-10.5 10.7 0 5.8 4.5 10.7 10.4 10.7 3 0 5.8-1.2 7.7-3.3l2.1 2.2c-2.5 2.5-5.9 4-9.8 4-7.8 0-13.8-6.1-13.8-13.6-.1-7.4 5.9-13.6 13.7-13.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorT1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                0
              )}
              d="M236.3 44.6h-9.8v-2.9h22.9v2.9h-9.8v23.6h-3.3V44.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorT1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                1
              )}
              d="M236.3 86.3h-9.8v-2.9h22.9v2.9h-9.8v23.6h-3.3V86.3z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorT1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                2
              )}
              d="M236.3 128h-9.8v-2.9h22.9v2.9h-9.8v23.6h-3.3V128z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorT1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                3
              )}
              d="M236.3 169.7h-9.8v-2.9h22.9v2.9h-9.8v23.6h-3.3v-23.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorT1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                4
              )}
              d="M236.3 211.4h-9.8v-2.9h22.9v2.9h-9.8V235h-3.3v-23.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorT1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                5
              )}
              d="M236.3 253.1h-9.8v-2.9h22.9v2.9h-9.8v23.6h-3.3v-23.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorT1}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line2Offset,
                6
              )}
              d="M236.3 294.7h-9.8v-2.9h22.9v2.9h-9.8v23.6h-3.3v-23.6z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                0
              )}
              d="M292.2 31.2c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8 0 13.8 6.1 13.8 13.6zm-3.4 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7s10.4-4.8 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                1
              )}
              d="M292.2 73.7c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8 0 13.8 6.1 13.8 13.6zm-3.4 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7s10.4-4.8 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                2
              )}
              d="M292.2 116.2c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8 0 13.8 6.1 13.8 13.6zm-3.4 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7s10.4-4.8 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                3
              )}
              d="M292.2 158.7c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8 0 13.8 6.1 13.8 13.6zm-3.4 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7s10.4-4.8 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                4
              )}
              d="M292.2 201.3c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8-.1 13.8 6 13.8 13.6zm-3.4 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7 5.9-.1 10.4-4.9 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                5
              )}
              d="M292.2 243.8c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8-.1 13.8 6.1 13.8 13.6zm-3.4 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7 5.9-.1 10.4-4.9 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                6
              )}
              d="M292.2 286.3c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8-.1 13.8 6.1 13.8 13.6zm-3.4 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7 5.9-.1 10.4-4.9 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorO2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line2Offset,
                7
              )}
              d="M292.2 328.8c0 7.6-6 13.6-13.8 13.6-7.8 0-13.8-6.1-13.8-13.6s6-13.6 13.8-13.6c7.8-.1 13.8 6.1 13.8 13.6zm-3.4 0c0-5.9-4.5-10.7-10.4-10.7-5.8 0-10.4 4.8-10.4 10.7 0 5.8 4.5 10.7 10.4 10.7 5.9-.1 10.4-4.9 10.4-10.7z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                0
              )}
              d="M307.5 0h10.8c7.5 0 11.4 4.4 11.4 9.9 0 3.9-2.2 7.3-6 8.9l6.2 7.7h-4.1l-5.4-6.7c-1 .1-4 .1-4.8.1h-4.8v6.6h-3.3V0zm11.1 16.9c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7h-7.9v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                1
              )}
              d="M307.5 41.7h10.8c7.5 0 11.4 4.5 11.4 9.9 0 3.9-2.2 7.4-6 8.9l6.2 7.7h-4.1l-5.4-6.7c-1 .1-4 .1-4.8.1h-4.8v6.6h-3.3V41.7zm11.1 16.9c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7h-7.9v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                2
              )}
              d="M307.5 83.4h10.8c7.5 0 11.4 4.5 11.4 9.9 0 3.9-2.2 7.3-6 8.9l6.2 7.7h-4.1l-5.4-6.7c-1 .1-4 .1-4.8.1h-4.8v6.6h-3.3V83.4zm11.1 16.9c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7h-7.9v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                3
              )}
              d="M307.5 125.1h10.8c7.5 0 11.4 4.4 11.4 9.9 0 3.9-2.2 7.4-6 8.9l6.2 7.7h-4.1l-5.4-6.7c-1 .1-4 .1-4.8.1h-4.8v6.6h-3.3v-26.5zm11.1 16.9c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7h-7.9v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                4
              )}
              d="M307.5 166.8h10.8c7.5 0 11.4 4.5 11.4 9.9 0 3.9-2.2 7.4-6 8.9l6.2 7.7h-4.1l-5.4-6.7c-1 .1-4 .1-4.8.1h-4.8v6.6h-3.3v-26.5zm11.1 16.9c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7h-7.9v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                5
              )}
              d="M307.5 208.5h10.8c7.5 0 11.4 4.5 11.4 9.9 0 3.9-2.2 7.4-6 8.9l6.2 7.7h-4.1l-5.4-6.7c-1 .1-4 .1-4.8.1h-4.8v6.6h-3.3v-26.5zm11.1 16.9c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7h-7.9v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                6
              )}
              d="M307.5 250.2h10.8c7.5 0 11.4 4.5 11.4 9.9 0 3.9-2.2 7.3-6 8.9l6.2 7.7h-4.1l-5.4-6.7c-1 .1-4 .1-4.8.1h-4.8v6.6h-3.3v-26.5zm11.1 16.9c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7h-7.9v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter,
                line2Offset,
                7
              )}
              d="M307.5 291.8h10.8c7.5 0 11.4 4.5 11.4 9.9 0 3.9-2.2 7.3-6 8.9l6.2 7.7h-4.1l-5.4-6.7c-1 .1-4 .1-4.8.1h-4.8v6.6h-3.3v-26.5zm11.1 17c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7h-7.9v14h7.9z"
            />
            <path
              className={`${styles.logoLetter} ${styles.projectorR2}`}
              style={getLetterStyle(
                screenSize,
                positionFactor,
                letter++,
                line2Offset,
                8
              )}
              d="M307.5 333.5h10.8c7.5 0 11.4 4.5 11.4 9.9 0 3.9-2.2 7.3-6 8.9l6.2 7.7h-4.1l-5.4-6.7c-1 .1-4 .1-4.8.1h-4.8v6.6h-3.3v-26.5zm11.1 17c4.6 0 7.6-3.1 7.6-7s-3-7-7.6-7h-7.9v14h7.9z"
            />
          </svg>
        </Link>
      </div>
      <div
        className={styles.quickBookMobileContainer}
        style={{
          top: `${mainNavHeightUpdated / 2 - 27.5}px`,
          display: isOpened ? `none` : `block`,
        }}
      >
        <QuickBookingButton
          className={`${styles.quickBookMobileBtn} ${isQuickBookOpened ? styles.quickBookOpened : ''
            }`}
          modalClass={styles.quickBookMobileModal}
          onModalOpen={setIsQuickBookOpened}
        />
      </div>
      <div
        className={styles.hamburger}
        style={{
          top: `${mainNavHeightUpdated / 2 - 34}px`,
        }}
        onClick={handleHamburgerClick}
      >
        <HamburgerIcon isOpened={isOpened} />
      </div>
      <ul
        ref={itemsRef}
        className={`${styles.items} z-40 ${isOpened ? styles.itemsOpened : ''}`}
      >
        <MenuItems
          expandedItems={expandedItems}
          navLinks={navLinks}
          forceCloseNav={forceCloseNav}
          handleKeyDown={handleKeyDown}
          itemWithFocus={itemWithFocus}
          isOpened={isOpened}
          toggleExpanded={toggleExpanded}
        />
      </ul>
    </nav>
  )
}

MainNav.propTypes = {
  onNavResize: PropTypes.func,
}

export default MainNav
