import React, { useState } from 'react'
import * as Yup from 'yup'

import styles from './mailing-subscription.module.css'
import { classes } from '../util/components'

const formSchema = Yup.object().shape({
  email: Yup.string().email(),
})

const MailingSubscription = ({ mailingListID }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formValue, setFormValue] = useState({ email: '' })
  const [errors, setErrors] = useState(null)

  const handleSubmit = event => {
    typeof window !== "undefined" && window.gtag && window.gtag("event", "subscribe-button-click", { 
      event_category: 'Mailling List',
      event_label: 'Projector',
     })
    if (isSubmitting || hasError) {
      event.preventDefault()
      return
    }

    setIsSubmitting(true)
  }

  const handleEmailChange = event => {
    const fVal = { ...formValue, email: event.target.value }
    setFormValue(fVal)
    formSchema
      .validateAt('email', fVal)
      .then(() => {
        setErrors({ ...errors, email: null })
      })
      .catch(err => {
        setErrors({ ...errors, email: err })
      })
  }

  const hasError = errors && Object.values(errors).some(e => !!e)

  return (
    <div
      className={`${styles.mailingWrapper} flex flex-col md:flex-row items-center justify-between`}
    >
      <div className={`${styles.mailingBlurb} flex-0`}>
        <p>Join our mailing list for updates and invites</p>
      </div>

      <form
        className={classes(
          styles.mailingForm,
          'flex-1',
          'flex',
          'flex-col',
          'sm:flex-row sm:items-center',
          hasError && styles.hasError
        )}
        onSubmit={handleSubmit}
        action={`https://TheProjector.us9.list-manage.com/subscribe/post?u=e1bcc4d93110387113a6ba141&amp;id=${mailingListID}`}
        method="post"
        target="_blank"
      >
        <input
          className={classes(
            styles.mailingInput,
            'flex-1',
            'text-center',
            'md:text-left',
            errors && errors.email && `${styles.error} error`
          )}
          type="email"
          name="EMAIL"
          aria-label="Your email address"
          placeholder="ENTER YOUR EMAIL"
          onChange={handleEmailChange}
          value={formValue.email}
        />
        <div className={styles.nonce} aria-hidden="true">
          <input
            type="text"
            name="b_e1bcc4d93110387113a6ba141_0183342771"
            tabIndex="-1"
            readOnly
            value=""
          />
        </div>
        <button
          type="submit"
          className={classes(
            styles.mailingButton,
            'self-center',
            'md:self-start',
            (isSubmitting || hasError) && 'btn-disabled'
          )}
          href="/"
        >
          Subscribe
        </button>
      </form>
    </div>
  )
}

export default MailingSubscription
