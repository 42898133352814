export function classes(...allTheClasses) {
  return (allTheClasses || []).reduce((result, c) => {
    if (c) {
      if (result) result += ' '
      result += c
    }

    return result
  }, '')
}
