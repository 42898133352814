module.exports = {
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
    extend: {},
    container: {
      center: true,
      padding: '2rem',
    },
    fontFamily: {
      display: ['Arboria-Book', 'sans-serif'],
      sans: ['Arboria-Book', 'sans-serif'],
    },
    textColor: {
      primary: '#fff',
      secondary: '#ffed4a',
      danger: '#e3342f',
    },
  },
  variants: {},
  plugins: [],
}
