import { Link as GatsbyLink } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import PropTypes from 'prop-types'
import React from 'react'

const Link = ({ to, className, children, ...props }) => {
  if (!to)
    return (
      <GatsbyLink className={className} to="/" {...props}>
        {children}
      </GatsbyLink>
    )

  const currentHost =
    typeof window !== 'undefined'
      ? `${window.location.protocol}//${window.location.hostname}`
      : null

  if (currentHost && to.indexOf(currentHost) === 0) {
    to = to.replace(currentHost, '')
  }

  return (to.indexOf('/') === 0 && !(to.indexOf('/static/') === 0)) ||
    to.indexOf('#') === 0 ? (
    <GatsbyLink to={to} className={className} {...props}>
      {children}
    </GatsbyLink>
  ) : (
    <OutboundLink
      href={to}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </OutboundLink>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Link
